<script setup lang="ts">
import { type Component } from 'vue';
import {
  CompanyInfo,
  PrivacyPolicy,
  CookiePolicy,
  UserTerms,
  License,
  ContractOffer,
} from '@glow/shared/components/policies';
import { UpArrowIcon } from '@glow/shared/components/icons';
import { type LinkWithTitle } from '@glow/shared/utils/types';
import { APP_NAME } from '@glow/shared/lib/constants';

const props = defineProps<{
  navigationLinks: LinkWithTitle[];
}>();

type Document = {
  name: string;
  title: string;
  modalTitle: string;
  Component: Component;
};
const documents: Document[] = [
  {
    name: 'companyInfo',
    title: 'Информация о компании',
    modalTitle: 'Информация о компании',
    Component: CompanyInfo,
  },
  {
    name: 'userTerms',
    title: 'Пользовательское соглашение',
    modalTitle: 'Пользовательское соглашение',
    Component: UserTerms,
  },
  {
    name: 'privacyPolicy',
    title: 'Обработка персональных данных',
    modalTitle:
      'Политика ООО «Квизрум» в отношении обработки персональных данных',
    Component: PrivacyPolicy,
  },
  {
    name: 'cookiePolicy',
    title: 'Использование cookie-файлов',
    modalTitle: 'Политика использования cookie-файлов',
    Component: CookiePolicy,
  },
  {
    name: 'license',
    title: 'Лицензия',
    modalTitle: 'Лицензионный договор',
    Component: License,
  },
  {
    name: 'contractOffer',
    title: 'Договор оферты',
    modalTitle: 'Договор-оферта',
    Component: ContractOffer,
  },
];

const { staticUrl } = useRuntimeConfig().public;
const openedDocument = ref<string>();
</script>

<template>
  <footer class="footer">
    <nav class="menu container">
      <div class="d-none d-lg-flex">
        <div class="image">
          <NuxtImg format="webp" :src="`${staticUrl}/landing/menu-image.png`" />
        </div>
      </div>
      <div class="col-md-4 col-lg-3 mb-4">
        <div class="title">Навигация</div>
        <div class="column">
          <NuxtLink
            v-for="(link, index) in props.navigationLinks"
            :key="index"
            :to="link.link"
          >
            <span class="item">{{ link.title }}</span>
          </NuxtLink>
        </div>
      </div>
      <div class="mb-4">
        <div class="title">Соц. сети</div>
        <div class="column">
          <NuxtLink to="#"><span class="item">Instagram</span></NuxtLink>
          <NuxtLink to="#"><span class="item">Telegram</span></NuxtLink>
          <NuxtLink to="#"><span class="item">Facebook</span></NuxtLink>
        </div>
      </div>
      <div class="mb-4">
        <div class="title">Документы</div>
        <div class="column">
          <span
            v-for="doc in documents"
            :key="doc.name"
            class="item"
            :to="`#${doc.name}`"
            @click="openedDocument = doc.name"
          >
            {{ doc.title }}
          </span>
        </div>
      </div>
      <div class="d-md-none">
        <div class="mb-3">© 2023 «{{ APP_NAME }}»</div>
      </div>
    </nav>
    <div class="down">
      <div class="container down-block">
        <div class="d-none d-md-block">© 2023 «{{ APP_NAME }}»</div>
        <a href="#">
          <span class="totop">
            <UpArrowIcon />
          </span>
        </a>
      </div>
    </div>
  </footer>

  <AppModal
    v-for="document in documents"
    :key="document.name"
    width="lg"
    :model-value="openedDocument === document.name"
    :title="document.modalTitle"
    @update:model-value="openedDocument = undefined"
  >
    <component :is="document.Component" />
  </AppModal>
</template>

<style scoped lang="scss">
.menu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 50px;
  max-height: 490px;
  gap: 1rem;

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-down(md) {
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    grid-template-columns: 1fr;
    padding: 0 16px;
  }

  .image {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  .title {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }

  .item {
    font-size: 17px;
    line-height: 22px;
    font-weight: 500;
    color: var(--app-color-black);
    cursor: pointer;

    &:hover {
      color: var(--app-color-primary);
    }

    @include media-breakpoint-only(lg) {
      font-size: 15px;
      line-height: 20px;
    }
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease;
  }

  &-enter-from,
  &-leave-to {
    max-height: 0;
    margin-top: 0;
    opacity: 0;
  }
}

.footer {
  padding: 50px 0;
  font-size: 17px;
  line-height: 22px;
  font-weight: 500;
  color: #fff;
  background: var(--app-gradient-primary);

  .menu {
    max-height: none;
    margin-top: 0;
    margin-bottom: 50px;

    .item {
      color: #fff;
    }

    @include media-breakpoint-down(md) {
      height: auto;
      overflow-y: hidden;
    }
  }

  .down {
    padding-top: 40px;
    border-top: 1px solid #e5e5e5;

    .totop {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border-radius: 50%;
      border: 3px solid #fff;

      svg {
        width: 34px;
        height: 34px;
        color: #fff;
      }
    }
  }

  .down-block {
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }
  }
}
</style>
